<template>
  <div class="home-content">
    <div class="header">
      <home-header />
    </div>
    <div class="leaderboard">
      <leader-board />
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  components: {
    HomeHeader: () => import('./components/HomeHeader'),
    LeaderBoard: () => import('./components/LeaderBoard'),
  },
  computed: {

  },
};
</script>

<style scoped lang="scss">
.home-content {
  background-color: $background-color;
  min-height: 100%;
  margin: 20px auto;

  .header {
    margin-bottom: 10px;
    min-height: 64px;
  }

  .condition {
    margin-bottom: 10px;
    min-height: 661px;
  }

  .center {
    margin-bottom: 10px;
    min-height: 111px;
  }
}
</style>
